<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '120px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-form-item label="游戏ID" name="mask_id">
              <a-input v-model:value.number="search.data.mask_id" placeholder="请输入游戏ID" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <!-- <a-col v-bind="searchCol">
            <a-form-item label="userId" name="user_id">
              <a-input v-model:value.number="search.data.user_id" placeholder="请输入userId" allowClear> </a-input>
            </a-form-item>
          </a-col> -->

          <a-col v-bind="searchCol">
            <a-form-item label="账号" name="account">
              <a-input v-model:value="search.data.account" placeholder="请输入账号" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="游戏昵称" name="nick_name">
              <a-input v-model:value="search.data.nick_name" placeholder="请输入游戏昵称" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-row type="flex" align="top" :gutter="10">
              <a-col :flex="1">
                <a-form-item label="持有钻石" name="min_bearns">
                  <a-input-number v-model:value.number="search.data.min_bearns" placeholder="最小值" allowClear style="width: 100%"> </a-input-number>
                </a-form-item>
              </a-col>

              <a-col> <div style="margin-top: 5px">~</div> </a-col>

              <a-col :flex="1">
                <a-form-item label="" name="max_bearns">
                  <a-input-number v-model:value.number="search.data.max_bearns" placeholder="最大值" allowClear style="width: 100%"> </a-input-number>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>

          <a-col v-bind="searchCol">
            <a-row type="flex" align="top" :gutter="10">
              <a-col :flex="1">
                <a-form-item label="注册时间" name="start_reg_time">
                  <a-date-picker v-model:value="search.data.start_reg_time" placeholder="开始时间" allowClear style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>

              <a-col> <div style="margin-top: 5px">~</div> </a-col>

              <a-col :flex="1">
                <a-form-item label="" name="end_reg_time">
                  <a-date-picker v-model:value="search.data.end_reg_time" placeholder="结束时间" allowClear style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>

          <a-col v-bind="searchCol">
            <a-row type="flex" align="top" :gutter="10">
              <a-col :flex="1">
                <a-form-item label="最后登录时间" name="start_new_login_time">
                  <a-date-picker v-model:value="search.data.start_new_login_time" placeholder="开始时间" allowClear style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>

              <a-col> <div style="margin-top: 5px">~</div> </a-col>

              <a-col :flex="1">
                <a-form-item label="" name="end_new_login_time">
                  <a-date-picker v-model:value="search.data.end_new_login_time" placeholder="结束时间" allowClear style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="注册IP" name="ip_addr">
              <a-input v-model:value="search.data.ip_addr" placeholder="请输入注册IP" allowClear> </a-input>
            </a-form-item>
          </a-col>
        </l-search-container>
      </a-form>
    </template>
    <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="id" :loading="loading">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex == 'state_id'">
          <a-tag color="success" v-if="record.state_id == 1">正常</a-tag>
          <a-tag color="error" v-if="record.state_id == 2">封号</a-tag>
        </template>
        <template v-if="column.dataIndex == 'thirdpart_headurl'">
          <a-avatar :src="record.thirdpart_headurl" size="30" />
        </template>
        <template v-if="column.dataIndex == 'total_bearns'">
          <span class="l-font-success l-bold">{{ record.total_bearns }}</span>
        </template>
        <template v-if="column.dataIndex == 'first_tag'">
          {{ record.first_tag == 1 ? "是" : "否" }}
        </template>
        <template v-if="column.dataIndex == 'is_first_change_name'">
          <a-tag color="success" v-if="record.is_first_change_name == 1">已修改</a-tag>
          <a-tag color="warning" v-if="record.is_first_change_name == 0">未修改</a-tag>
        </template>
        <template v-if="column.dataIndex == 'sex_id'">
          {{ getLabelByValue(sexTypes, record.sex_id) }}
        </template>

        <template v-if="column.dataIndex == 'action'">
          <l-auth-text-button auth="us_user_change_pwd_btn" type="warning" primary @click="showChangePwd(record)">
            <LockOutlined />
            修改密码
          </l-auth-text-button>
          <l-auth-text-button auth="us_user_change_state_btn" type="primary" primary @click="showChangeState(record)">
            <EditFilled />
            封号/解封
          </l-auth-text-button>
        </template>
      </template>
    </l-table>

    <a-modal v-model:visible="visible" title="修改状态" @cancel="visible = false" destroyOnClose>
      <ChangeState ref="edit_ref" @close="visible = false" @success="onChangeStateSuccess" v-model:id="editId" />
    </a-modal>

    <a-modal v-model:visible="changePwdVisible" title="修改密码" @cancel="changePwdVisible = false" destroyOnClose>
      <ChangePwd @close="changePwdVisible = false" v-model:id="editId" />
    </a-modal>
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { getLabelByValue, sexTypes } from "@/common/head";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { message, PaginationProps } from "ant-design-vue";
import ChangeState from "./ChangeState.vue";
import ChangePwd from "./ChangePwd.vue";
// import { showConfirm } from "@/common/utils";

const columns = [
  {
    title: "游戏ID",
    width: 80,
    dataIndex: "mask_id",
    fixed: "left",
  },

  // {
  //   title: "user_id",
  //   width: 90,
  //   dataIndex: "id",
  //   // fixed: "left",
  // },

  {
    title: "状态",
    width: 70,
    dataIndex: "state_id",
    // fixed: "left",
  },

  {
    title: "账号",
    width: 120,
    dataIndex: "account",
    ellipsis: true,
  },

  {
    title: "微信昵称",
    width: 140,
    dataIndex: "user_name",
    ellipsis: true,
  },

  {
    title: "游戏昵称",
    width: 140,
    dataIndex: "nick_name",
    ellipsis: true,
  },

  {
    title: "头像",
    width: 70,
    dataIndex: "thirdpart_headurl",
    ellipsis: true,
  },

  {
    title: "持有钻石",
    width: 120,
    dataIndex: "total_bearns",
    ellipsis: true,
  },

  {
    title: "性别",
    width: 70,
    dataIndex: "sex_id",
    ellipsis: true,
  },

  {
    title: "注册IP",
    width: 140,
    dataIndex: "ip_addr",
    ellipsis: true,
  },

  {
    title: "注册时间",
    width: 180,
    dataIndex: "register_time",
    ellipsis: true,
  },

  {
    title: "电话",
    width: 150,
    dataIndex: "rel_auth_phone",
    ellipsis: true,
  },

  {
    title: "邮箱",
    width: 150,
    dataIndex: "email_addr",
    ellipsis: true,
  },

  {
    title: "是否首次改名",
    width: 100,
    dataIndex: "is_first_change_name",
    ellipsis: true,
  },

  {
    title: "是否首次登录",
    width: 100,
    dataIndex: "first_tag",
    ellipsis: true,
  },

  {
    title: "最后登录时间",
    width: 180,
    dataIndex: "new_login_time",
    ellipsis: true,
  },

  // {
  //   title: "年龄",
  //   width: 70,
  //   dataIndex: "age",
  //   ellipsis: true,
  // },

  // {
  //   title: "等级",
  //   width: 70,
  //   dataIndex: "level",
  //   ellipsis: true,
  // },

  // {
  //   title: "经验",
  //   width: 70,
  //   dataIndex: "exp",
  //   ellipsis: true,
  // },

  {
    title: "unionID",
    width: 180,
    dataIndex: "thirdpart_unionid",
    ellipsis: true,
  },

  {
    title: "app openid",
    width: 180,
    dataIndex: "thirdpart_app_openid",
    ellipsis: true,
  },

  {
    title: "公开openid",
    width: 180,
    dataIndex: "thirdpart_public_openid",
    ellipsis: true,
  },

  {
    title: "token",
    width: 180,
    dataIndex: "thirdpart_token",
    ellipsis: true,
  },

  {
    title: "微信昵称",
    width: 180,
    dataIndex: "thirdpart_nick_name",
    ellipsis: true,
  },

  {
    title: "第三方平台认证时间",
    width: 180,
    dataIndex: "thirdpart_auth_time",
    ellipsis: true,
  },

  {
    title: "在线时长",
    width: 120,
    dataIndex: "online_duration",
    ellipsis: true,
  },

  {
    title: "操作",
    align: "center",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 120,
  },
];

export default defineComponent({
  components: {
    ChangeState,
    ChangePwd,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        mask_id: undefined,
        user_id: undefined,
        account: "",
        nick_name: "",
        min_bearns: undefined,
        max_bearns: undefined,
        start_reg_time: undefined,
        end_reg_time: undefined,
        start_new_login_time: undefined,
        end_new_login_time: undefined,
        ip_addr: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const edit_ref = ref();

    const formRef = ref();

    const editId = ref(0);

    //是否显示添加/编辑弹窗
    const visible = ref(false);
    const changePwdVisible = ref(false);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_user", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击编辑按钮
    const showChangeState = (row: any) => {
      editId.value = row.id;
      visible.value = true;
    };

    //点击修改密码按钮
    const showChangePwd = (row: any) => {
      editId.value = row.id;
      changePwdVisible.value = true;
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      onSearch();
    };

    //修改状态
    const changeState = async (row: any) => {
      try {
        await http.put(`us_user/${row.id}/states`, { state_id: row.state_id });
        message.success("操作成功");
      } catch (e) {
        console.log(e);
      }
    };

    //新增/修改成功
    const onChangeStateSuccess = () => {
      getList();
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      visible,
      columns,
      search,
      pagination,
      edit_ref,
      formRef,
      editId,
      loading,
      changePwdVisible,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      sexTypes,
      getLabelByValue,
      changeState,
      showChangePwd,
      onChangeStateSuccess,
      showChangeState,
    };
  },
});
</script>
