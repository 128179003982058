
import http from "@/common/http";
import { getLabelByValue, sexTypes } from "@/common/head";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { message, PaginationProps } from "ant-design-vue";
import ChangeState from "./ChangeState.vue";
import ChangePwd from "./ChangePwd.vue";
// import { showConfirm } from "@/common/utils";

const columns = [
  {
    title: "游戏ID",
    width: 80,
    dataIndex: "mask_id",
    fixed: "left",
  },

  // {
  //   title: "user_id",
  //   width: 90,
  //   dataIndex: "id",
  //   // fixed: "left",
  // },

  {
    title: "状态",
    width: 70,
    dataIndex: "state_id",
    // fixed: "left",
  },

  {
    title: "账号",
    width: 120,
    dataIndex: "account",
    ellipsis: true,
  },

  {
    title: "微信昵称",
    width: 140,
    dataIndex: "user_name",
    ellipsis: true,
  },

  {
    title: "游戏昵称",
    width: 140,
    dataIndex: "nick_name",
    ellipsis: true,
  },

  {
    title: "头像",
    width: 70,
    dataIndex: "thirdpart_headurl",
    ellipsis: true,
  },

  {
    title: "持有钻石",
    width: 120,
    dataIndex: "total_bearns",
    ellipsis: true,
  },

  {
    title: "性别",
    width: 70,
    dataIndex: "sex_id",
    ellipsis: true,
  },

  {
    title: "注册IP",
    width: 140,
    dataIndex: "ip_addr",
    ellipsis: true,
  },

  {
    title: "注册时间",
    width: 180,
    dataIndex: "register_time",
    ellipsis: true,
  },

  {
    title: "电话",
    width: 150,
    dataIndex: "rel_auth_phone",
    ellipsis: true,
  },

  {
    title: "邮箱",
    width: 150,
    dataIndex: "email_addr",
    ellipsis: true,
  },

  {
    title: "是否首次改名",
    width: 100,
    dataIndex: "is_first_change_name",
    ellipsis: true,
  },

  {
    title: "是否首次登录",
    width: 100,
    dataIndex: "first_tag",
    ellipsis: true,
  },

  {
    title: "最后登录时间",
    width: 180,
    dataIndex: "new_login_time",
    ellipsis: true,
  },

  // {
  //   title: "年龄",
  //   width: 70,
  //   dataIndex: "age",
  //   ellipsis: true,
  // },

  // {
  //   title: "等级",
  //   width: 70,
  //   dataIndex: "level",
  //   ellipsis: true,
  // },

  // {
  //   title: "经验",
  //   width: 70,
  //   dataIndex: "exp",
  //   ellipsis: true,
  // },

  {
    title: "unionID",
    width: 180,
    dataIndex: "thirdpart_unionid",
    ellipsis: true,
  },

  {
    title: "app openid",
    width: 180,
    dataIndex: "thirdpart_app_openid",
    ellipsis: true,
  },

  {
    title: "公开openid",
    width: 180,
    dataIndex: "thirdpart_public_openid",
    ellipsis: true,
  },

  {
    title: "token",
    width: 180,
    dataIndex: "thirdpart_token",
    ellipsis: true,
  },

  {
    title: "微信昵称",
    width: 180,
    dataIndex: "thirdpart_nick_name",
    ellipsis: true,
  },

  {
    title: "第三方平台认证时间",
    width: 180,
    dataIndex: "thirdpart_auth_time",
    ellipsis: true,
  },

  {
    title: "在线时长",
    width: 120,
    dataIndex: "online_duration",
    ellipsis: true,
  },

  {
    title: "操作",
    align: "center",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 120,
  },
];

export default defineComponent({
  components: {
    ChangeState,
    ChangePwd,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        mask_id: undefined,
        user_id: undefined,
        account: "",
        nick_name: "",
        min_bearns: undefined,
        max_bearns: undefined,
        start_reg_time: undefined,
        end_reg_time: undefined,
        start_new_login_time: undefined,
        end_new_login_time: undefined,
        ip_addr: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const edit_ref = ref();

    const formRef = ref();

    const editId = ref(0);

    //是否显示添加/编辑弹窗
    const visible = ref(false);
    const changePwdVisible = ref(false);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_user", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击编辑按钮
    const showChangeState = (row: any) => {
      editId.value = row.id;
      visible.value = true;
    };

    //点击修改密码按钮
    const showChangePwd = (row: any) => {
      editId.value = row.id;
      changePwdVisible.value = true;
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      onSearch();
    };

    //修改状态
    const changeState = async (row: any) => {
      try {
        await http.put(`us_user/${row.id}/states`, { state_id: row.state_id });
        message.success("操作成功");
      } catch (e) {
        console.log(e);
      }
    };

    //新增/修改成功
    const onChangeStateSuccess = () => {
      getList();
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      visible,
      columns,
      search,
      pagination,
      edit_ref,
      formRef,
      editId,
      loading,
      changePwdVisible,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      sexTypes,
      getLabelByValue,
      changeState,
      showChangePwd,
      onChangeStateSuccess,
      showChangeState,
    };
  },
});
